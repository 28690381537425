<template>
  <div class="contents">
    <div class="layout">
      <!-- s::title-breadcrumbs -->
      <div class="page-top">
        <h2>{{ getMenuLabel() }} 관리</h2>
        <div class="breadcrumbs">
          <span>컨텐츠 관리</span>
          <span>{{ getMenuLabel() }} 관리</span>
          <span class="color-black">{{ articleUuid ? item.title : '등록' }}</span>
        </div>
      </div>
      <!-- e::title-breadcrumbs -->

      <div class="white-panel mt30">
        <div class="table-wrap">
          <table class="table-type2">
            <colgroup>
              <col style="width:150px;">
              <col>
            </colgroup>
            <tbody>
            <tr v-if="articleType === 'news'">
              <th>종류<em class="required">*</em></th>
              <td>
                <select v-model="item.type">
                  <option value="" disabled>선택</option>
                  <option v-for="type in ARTICLE_NEWS_TYPE" :value="type.value">{{ type.label }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>제목<em class="required">*</em></th>
              <td>
                <input type="text" v-model="item.title"/>
              </td>
            </tr>
            <tr>
              <th>시작일<em class="required">*</em></th>
              <td>
                <Datepicker inputmode="numeric" v-model="item.periodFrom" :enable-time-picker="false" :format="'yyyy-MM-dd'"
                            v-mask="'####-##-##'" :text-input="{format: 'yyyy-MM-dd'}" placeholder="YYYY-MM-DD" auto-apply/>
              </td>
            </tr>
            <tr>
              <th>종료일<em class="required">*</em></th>
              <td>
                <Datepicker inputmode="numeric" v-model="item.periodTo" :enable-time-picker="false" :format="'yyyy-MM-dd'"
                            v-mask="'####-##-##'" :text-input="{format: 'yyyy-MM-dd'}" placeholder="YYYY-MM-DD" auto-apply/>
              </td>
            </tr>
            <tr>
              <th>날짜 추가 설명</th>
              <td>
                <input type="text" maxlength="255" v-model="item.periodMemo"/>
              </td>
            </tr>
            <tr>
              <th>시작시간</th>
              <td>
                <input type="text" inputmode="numeric" v-model="item.timeFrom" v-mask="'##:##'" placeholder="HH:mm"/>
              </td>
            </tr>
            <tr>
              <th>종료시간</th>
              <td>
                <input type="text" inputmode="numeric" v-model="item.timeTo" v-mask="'##:##'" placeholder="HH:mm"/>
              </td>
            </tr>
            <tr>
              <th>시간 추가 설명</th>
              <td>
                <input type="text" maxlength="255" v-model="item.timeMemo"/>
              </td>
            </tr>
            <tr>
              <th>장소<em class="required">*</em></th>
              <td>
                <input type="text" v-model="item.location"/>
              </td>
            </tr>
            <tr>
              <th>내용<em class="required">*</em></th>
              <td>
                <textarea v-model="item.content"/>
              </td>
            </tr>
            <tr>
              <th>공식 URL</th>
              <td>
                <input type="text" maxlength="255" v-model="item.originLink"/>
              </td>
            </tr>
            <tr>
              <th>연락처<em class="required">*</em></th>
              <td>
                <input type="text" v-model="item.contactPhoneNum" v-mask="'###-####-####'"/>
              </td>
            </tr>
            <tr>
              <th>이메일</th>
              <td>
                <input type="text" v-model="item.contactEmail"/>
              </td>
            </tr>
            <tr>
              <th style="vertical-align: top;">메인 이미지<em class="required">*</em></th>
              <td>
                <div class="file-list">
                  <ul>
                    <li>
                      <div class="attachment">
                        <div class="filebox2">
                          <label for="ex_file">
                            <span>{{ item.imageUrl }}</span>
                          </label>
                          <input type="file" id="ex_file" @change="previewImage" accept="image/*"/>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <img v-if="item.imageUrl" :src="item.imageUrl" style="width: 200px; height: 200px;" alt=""/>
                </div>
              </td>
            </tr>
            <tr>
              <th>공개여부<em class="required">*</em></th>
              <td>
                <select v-model="item.isHidden">
                  <option value="false">공개</option>
                  <option value="true">비공개</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>Home 전시<em class="required">*</em></th>
              <td>
                <select v-model="item.isHomeVisible">
                  <option value="true">공개</option>
                  <option value="false">비공개</option>
                </select>
              </td>
            </tr>
            <tr v-if="articleUuid">
              <th>생성자</th>
              <td>
                <input type="text" :value="item.createdBy" disabled/>
              </td>
            </tr>
            <tr v-if="articleUuid">
              <th>생성일시</th>
              <td>
                <input type="text" :value="item.createdAt" disabled/>
              </td>
            </tr>
            <tr v-if="articleUuid">
              <th>최종 수정자</th>
              <td>
                <input type="text" :value="item.updatedBy" disabled/>
              </td>
            </tr>
            <tr v-if="articleUuid">
              <th>최종 수정일시</th>
              <td>
                <input type="text" :value="item.updatedAt" disabled/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="button-panel mt30">
        <div class="left">
          <router-link :to="{ name: 'Article', params: { articleType: articleType }}" class="button white-button round-button gray2">목록</router-link>
        </div>
        <div class="right">
          <button @click="saveItem" type="button" class="button navy-button">{{articleUuid ? '수정하기' : '등록하기'}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Datepicker from '@vuepic/vue-datepicker';
import {onMounted, ref} from 'vue';
import {apiClient} from "@/services/auth-header";
import {useRoute} from "vue-router";
import {format} from 'date-fns';
import {ARTICLE_NEWS_TYPE} from "@/common/common-enum";

const route = useRoute();
const articleUuid = ref(route.params.uuid);
const articleType = ref(route.params.articleType);

const item = ref({
  uuid: null,
  type: null, // 뉴스에서만 사용
  title: null,
  content: null,
  periodFrom: null,
  periodTo: null,
  periodMemo: null,
  timeFrom: null,
  timeTo: null,
  timeMemo: null,
  location: null,
  originLink: null,
  contactPhoneNum: null,
  contactEmail: null,
  imageUrl: null,
  isHidden: false,
  isHomeVisible: false,
  createdAt: null,
  createdBy: null,
  updatedAt: null,
  updatedBy: null,
})

const selectedFile = ref(null);
const API_URL = "/contents";

const getMenuLabel = () => {
  if (articleType.value === 'exhibitions') {
    return '전시';
  } else if (articleType.value === 'events') {
    return '이벤트'
  }
  return '뉴스'
}

const fetchItem = async () => {
  try {
    const response = await apiClient.get(API_URL + `/articles/${articleType.value}/${articleUuid.value}`);
    item.value = response.data;
  } catch (error) {
    alert(error.message);
  }
};

const previewImage = (event) => {
  selectedFile.value = event.target.files[0];
  item.value.imageUrl = URL.createObjectURL(event.target.files[0]);
};

const saveItem = async () => {
  if (!validateItem()) {
    return;
  }

  const msg = articleUuid.value ? "수정 하시겠습니까?" : "등록 하시겠습니까?";
  if (confirm(msg)) {
    const formData = new FormData();
    if (selectedFile.value) {
      formData.append('image', selectedFile.value);
    }

    item.value.periodFrom = format(item.value.periodFrom, 'yyyy-MM-dd');
    item.value.periodTo = format(item.value.periodTo, 'yyyy-MM-dd');
    formData.append('reqDto', JSON.stringify(item.value));

    try {
      if (!articleUuid.value) {
        // 등록
        await apiClient.post(API_URL + `/articles/${articleType.value}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      } else {
        // 수정
        await apiClient.put(API_URL + `/articles/${articleType.value}/${articleUuid.value}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }

      const resultMsg = articleUuid.value ? "수정 되었습니다." : "등록 되었습니다.";
      alert(resultMsg);
      if (articleUuid.value) {
        await fetchItem();
      } else {
        history.back();
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      alert(error.response.data);
    }
  }
};

const validateItem = () => {
  if (articleType.value === 'news' && !item.value.type) {
    alert('종류는 필수입니다.');
    return false;
  }

  if (!item.value.title) {
    alert('제목은 필수입니다.');
    return false;
  }

  if (!item.value.periodFrom) {
    alert('시작일은 필수입니다.');
    return false;
  }

  if (!item.value.periodTo) {
    alert('종료일은 필수입니다.');
    return false;
  }

  if (!item.value.location) {
    alert('장소는 필수입니다.');
    return false;
  }

  if (!item.value.content) {
    alert('내용은 필수입니다.');
    return false;
  }

  if (!item.value.contactPhoneNum) {
    alert('연락처는 필수입니다.');
    return false;
  }

  if (!item.value.imageUrl) {
    alert('이미지는 필수입니다.');
    return false;
  }

  if (item.value.isHidden === null) {
    alert('공개여부는 필수입니다.');
    return false;
  }

  return true;
}

onMounted(() => {
  if (articleUuid.value) {
    fetchItem();
  }
});
</script>