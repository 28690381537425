<template>
  <div class="contents">
    <div class="layout">

      <div class="page-top">
        <h2>이달의 아티스트 관리</h2>
        <div class="breadcrumbs">
          <span>컨텐츠 관리</span>
          <span class="color-black">이달의 아티스트 관리</span>
        </div>
      </div>

      <div class="white-panel mt30 pt30 pb30">
        <div class="search-panel">
          <table>
            <colgroup>
              <col style="width:150px;">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>검색어</th>
              <td>
                <div class="input-search">
                  <input v-model="searchQuery" @keyup.enter="fetchItems" type="text" name="search-input" placeholder="검색어 입력">
                  <i @click="fetchItems" class="button-search" style="cursor: pointer;"><img src="@/assets/images/ico/ico_search.svg" alt=""/></i>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="list-top mt50">
        <h3></h3>
        <div class="control-group">
          <div class="btn-group">
            <button @click="deleteItems" type="button" class="delete-button">삭제</button>
            <button @click="addBanner" type="button" class="navy-button add-button">등록</button>
          </div>
        </div>
      </div>

      <div class="white-panel mt20">
        <div class="table-wrap">
          <div class="scroll-box">
            <table class="table-type1">
              <colgroup>
                <col style="width:60px;">
                <col style="width:120px;">
                <col>
                <col>
                <col>
                <col style="width:120px;">
                <col style="width:120px;">
              </colgroup>
              <thead>
              <tr>
                <th>
									<span class="checkbox-type1">
										<input @click="toggleAllCheck" type="checkbox" id="allchk">
										<label for="allchk">체크</label>
									</span>
                </th>
                <th>관리 No.</th>
                <th>선정 년월</th>
                <th>ARTIST ID</th>
                <th>공개순서</th>
                <th>작성자</th>
                <th>등록일</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in items" :key="item.id" :style="item.selectedMonth !== formattedSelectedMonth? 'background-color: #ffe6e6;' : ''">
                <td>
                    <span class="checkbox-type1">
                        <input v-model="selectedItems" :id="item.id" type="checkbox" :value="item.id">
                        <label :for="item.id">체크</label>
                    </span>
                </td>
                <td>{{ item.id }}</td>
                <td>
                  <router-link :to="{ name: 'MonthlyArtistDetail', params: { id: item.id } }" class="subject">{{ item.selectedMonth }}</router-link>
                </td>
                <td>
                  {{ item.artistUserAccountId }}
                  <a v-if="item.artistUserAccountId"
                     :href="`https://article21.kr/artists/${item.artistUserAccountId}`"
                     target="_blank">
                    🔗
                  </a>
                </td>
                <td>{{ item.sortOrder }}</td>
                <td>{{ item.createdBy }}</td>
                <td>{{ item.createdAt }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref} from 'vue';
import {apiClient} from "@/services/auth-header";
import router from "@/router";

const items = ref([]);
const selectedItems = ref([]);
const searchQuery = ref('');
const API_URL = "/contents";

// 현재 날짜 가져오기
const today = new Date();
const selectedYear = ref(today.getFullYear());
const selectedMonth = ref(today.getMonth() + 1);
const formattedSelectedMonth = computed(() => {
  return selectedYear.value && selectedMonth.value
      ? `${selectedYear.value}.${String(selectedMonth.value).padStart(2, "0")}`
      : "";
});

const toggleAllCheck = (event) => {
  if (event.target.checked) {
    selectedItems.value = items.value.map(item => item.id);
  } else {
    selectedItems.value = [];
  }
};

const addBanner = () => {
  router.push({
    name: 'MonthlyArtistCreate'
  });
};

const fetchItems = async () => {
  try {
    const response = await apiClient.get(API_URL + '/monthly-artists', {
      params: {q: searchQuery.value}
    });
    items.value = response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const deleteItems = async () => {
  if (confirm("삭제 하시겠습니까?")) {
    try {
      const response = await apiClient.delete(API_URL + '/monthly-artists', {data: selectedItems.value});
      if (response.status === 200) {
        alert("삭제되었습니다.")
        selectedItems.value = [];
        await fetchItems();
      }
    } catch (error) {
      console.error('Error deleting items:', error);
    }
  }
};

onMounted(async () => {
  await fetchItems();
});
</script>