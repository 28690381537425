<template>
  <div class="contents">
    <div class="layout">
      <!-- s::title-breadcrumbs -->
      <div class="page-top">
        <h2>배너 관리</h2>
        <div class="breadcrumbs">
          <span>컨텐츠 관리</span>
          <span class="color-black">배너 관리</span>
        </div>
      </div>
      <!-- e::title-breadcrumbs -->

      <div class="white-panel mt30">
        <div class="table-wrap">
          <table class="table-type2">
            <colgroup>
              <col style="width:150px;">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>분류<em class="required">*</em></th>
              <td>
                <select v-model="item.type">
                  <option v-for="type in BANNER_TYPE" :value="type.value">{{ type.label }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>순서<em class="required">*</em></th>
              <td>
                <input type="number" v-model="item.sortOrder" max="100" maxlength="10 " style="max-width:540px;"/>
              </td>
            </tr>
            <tr>
              <th>설명<em class="required">*</em></th>
              <td>
                <input v-model="item.description" type="text" placeholder="설명을 입력하세요." style="max-width:540px;">
              </td>
            </tr>
            <tr>
              <th>분류<em class="required">*</em></th>
              <td>
                <select v-model="item.mediaType" @change="selectedType">
                  <option v-for="type in MEDIA_TYPE" :value="type.value">{{ type.label }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <th style="vertical-align: top;">첨부파일<em class="required">*</em></th>
              <td>
                <div class="file-list">
                  <ul>
                    <li>
                      <div class="attachment">
                        <div class="filebox2">
                          <label for="ex_file">
                            <span>{{ item.imageUrl }}</span>
                          </label>
                          <input type="file" id="ex_file" @change="handleFileUpload" :accept="fileAcceptType"/>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="attachment">
                        <div v-if="item.imageUrl" class="filebox2">
                          <img v-if="item.mediaType === MEDIA_TYPE[1].value" :src="item.imageUrl" alt=""/>
                          <video v-else controls>
                            <source :src="item.imageUrl" type="video/mp4">
                          </video>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <th>link URL</th>
              <td>
                <input type="text" maxlength="1000" v-model="item.linkUrl"/>
              </td>
            </tr>
            <tr>
              <th>공개여부<em class="required">*</em></th>
              <td>
                <select v-model="item.isHidden">
                  <option value="false">공개</option>
                  <option value="true">비공개</option>
                </select>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="button-panel mt30">
        <div class="left">
          <router-link :to="{ name: 'Banner' }" class="button white-button round-button gray2">목록</router-link>
        </div>
        <div class="right">
          <button @click="saveItem" type="button" class="button navy-button">{{ bannerUuid ? '수정하기' : '등록하기' }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, computed} from 'vue';
import {apiClient} from "@/services/auth-header";
import {useRoute} from "vue-router";
import {BANNER_TYPE, MEDIA_TYPE} from "@/common/common-enum";

const route = useRoute();
const bannerUuid = ref(route.params.uuid || null);

const item = ref({
  uuid: null,
  type: BANNER_TYPE[0].value,
  mediaType: MEDIA_TYPE[0].value,
  imageUrl: '',
  description: '',
  sortOrder: 0,
  linkUrl: '',
  isHidden: false,
})
const selectedFile = ref(null);
const API_URL = "/contents";

const selectedType = () => {
  selectedFile.value = null;
  item.value.imageUrl = '';
}
const handleFileUpload = (event) => {
  selectedFile.value = event.target.files[0];
  item.value.imageUrl = URL.createObjectURL(event.target.files[0]);
};

const fileAcceptType = computed(() => {
  return item.value.mediaType === MEDIA_TYPE[0].value ? "video/mp4" : "image/*";
});

const fileTypeLabel = computed(() => {
  return item.value.type === BANNER_TYPE[0].value ? "mp4" : "이미지";
});

const fetchItem = async () => {
  try {
    const response = await apiClient.get(`${API_URL}/banners/${bannerUuid.value}`);
    item.value = response.data;
  } catch (error) {
    console.error(error)
  }
};

const saveItem = async () => {

  if (!validateItem()) {
    return;
  }

  const formData = new FormData();

  if (selectedFile.value) {
    formData.append('image', selectedFile.value);
  }
  formData.append('reqDto', JSON.stringify(item.value));

  try {
    if (!bannerUuid.value) {
      // 등록
      await apiClient.post(API_URL + `/banner`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } else {
      // 수정
      await apiClient.put(API_URL + `/banner/${bannerUuid.value}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    }

    const resultMsg = bannerUuid.value ? "수정 되었습니다." : "등록 되었습니다.";
    alert(resultMsg);

    if (bannerUuid.value) {
      window.location.reload();
    } else {
      window.history.back();
    }
  } catch (error) {
    console.error('Error uploading file:', error);
    alert(error.response.data);
  }

};

const validateItem = () => {
  if (item.value.type === "") {
    alert("분류를 선택해 주세요.");
    return false;
  }

  if (item.value.sort < -1 || item.value.sort === "") {
    alert("순서를 입력해 주세요.");
    return false;
  }

  if (item.value.description === "") {
    alert("설명을 입력해 주세요.");
    return false;
  }

  if (!item.value.imageUrl) {
    alert("파일을 첨부해 주세요.");
    return false;
  }

  if (item.value.isHidden === null) {
    alert('공개여부는 필수입니다.');
    return false;
  }

  return true;
}

onMounted(() => {
  if (bannerUuid.value) {
    fetchItem();
  }
});
</script>